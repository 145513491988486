import React from 'react';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import {
  AppBar, Toolbar, Typography, Button, List,
  Dialog, DialogContent, DialogContentText, DialogActions,
} from '@material-ui/core';

import * as API from 'service/api';
import { getErrorMessage } from 'service/utility';

import { Spinner } from '../../statusIndicators';
import RevenueCategoryListItem from './RevenueCategoryListItem';


class AssignRevenueCategoryDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      selectedRevenueCategoryId: 0,
      revenueCategories: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;

    this.loadRevenueCategories();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadRevenueCategories = async () => {
    try {
      const { data: { data: revenueCategories_ } } = await API.findRevenueCategory('page=1&pageSize=999&isActive=1');
      const revenueCategories = [
        {
          label: 'Unassigned',
          value: null,
        },
      ].concat(revenueCategories_.map((revenueCategory_) => ({
        label: revenueCategory_.name,
        value: revenueCategory_.id,
      })));

      if (this._isMounted) {
        this.setState({
          revenueCategories,
          loading: false,
        });
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('API.findRevenueCategory error: ');
      console.log(errorMessage);

      toast.error(errorMessage);

      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }
  };

  handleRevenueCategorySelect = (selectedRevenueCategoryId) => {
    this.setState({ selectedRevenueCategoryId });
  };

  handleAssign = () => {
    this.props.onAssign(this.state.selectedRevenueCategoryId);
  };

  render() {
    const { isOpen, onClose } = this.props;
    const { loading, selectedRevenueCategoryId, revenueCategories } = this.state;

    return (
      <Dialog
        open={isOpen}
        maxWidth="sm"
        onClose={onClose}
      >
        <AppBar
          position="static"
          className="shadow-none"
        >
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
            >
              {'Assign Revenue Category'}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className="pt-3">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <DialogContentText>
                {'Select the revenue category you want to assign your products to:'}
              </DialogContentText>
              <List className="border">
                {revenueCategories.map((revenueCategory) => (
                  <RevenueCategoryListItem
                    key={revenueCategory.value}
                    revenueCategory={revenueCategory}
                    isSelected={selectedRevenueCategoryId === revenueCategory.value}
                    onSelect={this.handleRevenueCategorySelect}
                  />
                ))}
              </List>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            {'Cancel'}
          </Button>
          <Button
            color="primary"
            onClick={this.handleAssign}
            disabled={selectedRevenueCategoryId === 0}
          >
            {'Assign'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AssignRevenueCategoryDialog.propTypes = {
  isOpen: PropTypes.bool,
  onAssign: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};


export default AssignRevenueCategoryDialog;
