import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast, ToastContainer, Slide } from 'react-toastify';
import * as Sentry from '@sentry/browser';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { serviceWorkerSelector } from 'app/redux/serviceWorker/selectors';
import theme from 'config/theme';
import { getEnv } from 'service/env';
// eslint-disable-next-line no-unused-vars
import firebaseService from 'service/firebase';

import Region from './Region';
import Routes from './Routes';
import Services from './Services';


const env = getEnv();

if (env === 'production') {
  Sentry.init({
    dsn: 'https://0feac857787b42a0ba13c162ad1a3ebf@sentry.io/1434124',
    integrations: [
      Sentry.breadcrumbsIntegration({
        console: false,
      }),
    ],
  });
}


const App = () => {
  const serviceWorker = useSelector(serviceWorkerSelector);

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorker.registration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

      registrationWaiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  };

  if (serviceWorker.initialized) {
    console.log('App: Service Worker is initialized for the first time!');
  }

  if (serviceWorker.updated) {
    updateServiceWorker();

    return null;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <ToastContainer
        autoClose={false}
        draggable={false}
        position={toast.POSITION.BOTTOM_CENTER}
        hideProgressBar
        type={toast.TYPE.INFO}
        transition={Slide}
        className="toast-container"
        toastClassName="toast"
        bodyClassName="toast-body"
        progressClassName="toast-progress"
      />
      <BrowserRouter>
        <Region>
          <Services>
            <Routes />
          </Services>
        </Region>
      </BrowserRouter>
    </MuiThemeProvider>
  );
};


export default App;
