import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button,
} from '@material-ui/core';
import Dragula from 'react-dragula';

import { Spinner } from '../../statusIndicators';


class DraggableListDialog extends React.Component {
  constructor(props) {
    super(props);

    this.draggableRefLeft = null;
    this.draggableRefRight = null;
    this.drake = null;
  }

  componentDidMount() {
    if (this.draggableRefLeft && this.draggableRefRight) {
      this.drake = Dragula([this.draggableRefLeft, this.draggableRefRight], {
        moves: (el, container, handle) => handle.classList.contains('drag-handle'),
      });
    }
  }

  componentWillUnmount() {
    if (this.drake) {
      this.drake.destroy();
    }
  }

  setDraggableRefLeft = (ref) => {
    this.draggableRefLeft = ref;
    this.initializeDragula();
  };

  setDraggableRefRight = (ref) => {
    this.draggableRefRight = ref;
    this.initializeDragula();
  };

  initializeDragula = () => {
    if (this.draggableRefLeft && this.draggableRefRight && !this.drake) {
      this.drake = Dragula([this.draggableRefLeft, this.draggableRefRight], {
        moves: (el, container, handle) => handle.classList.contains('drag-handle'),
      });
    }
  };

  render() {
    const {
      open, title, itemTypeName, loading, leftItems, rightItems, onClose, onSave,
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        classes={{ paper: 'p-3 mt-3' }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <>
            <DialogTitle>
              {title || `Select ${itemTypeName}`}
            </DialogTitle>
            <DialogContent>
              {`Drag the ${itemTypeName} that you want from the left column to the right, in the order that you want them displayed.`}
              <div id="draggable-dialog-items-container" className="d-flex">
                <div
                  id="draggable-source-items"
                  ref={this.setDraggableRefLeft}
                  className="flex-1 mx-2 border draggable-container-left"
                  style={{
                    height: (leftItems.length + rightItems.length) * 34,
                  }}
                >
                  {leftItems.map((item) => (
                    <div
                      key={item.id}
                      data-itemid={item.id}
                      className="py-1 px-2 border bg-white drag-handle draggable-item"
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
                <div
                  id="draggable-target-items"
                  ref={this.setDraggableRefRight}
                  className="flex-1 mx-2 border draggable-container-right"
                  style={{
                    height: (leftItems.length + rightItems.length) * 34,
                  }}
                >
                  {rightItems.map((item) => (
                    <div
                      key={item.id}
                      data-itemid={item.id}
                      className="py-1 px-2 border bg-white drag-handle draggable-item"
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>
                {'Cancel'}
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={onSave}
              >
                {'Save'}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

DraggableListDialog.propTypes = {
  itemTypeName: PropTypes.string,
  leftItems: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  rightItems: PropTypes.array.isRequired,
  title: PropTypes.string,
};

DraggableListDialog.defaultProps = {
  itemTypeName: 'item',
  loading: false,
  title: '',
};

export default DraggableListDialog;
