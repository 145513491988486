export const ITEM_TYPES = {
  GIFT_CARD: 'GIFT_CARD',
  PASS: 'PASS',
  MEMBERSHIP: 'MEMBERSHIP',
  MEMBERSHIP_RESUME: 'MEMBERSHIP_RESUME',
  PASS_OR_MEMBERSHIP: 'PASS_OR_MEMBERSHIP',
  FEE: 'FEE',
  EVENT_BOOKING_TICKET: 'EVENT_BOOKING_TICKET',
  EVENT_SERIES_TICKET: 'EVENT_SERIES_TICKET',
  EVENT_BOOKING_INVOICE: 'EVENT_BOOKING_INVOICE',
  PRODUCT: 'PRODUCT',
  PRICING_TIER: 'PRICING_TIER',
  TIP: 'TIP',
  REVENUE_CATEGORY: 'REVENUE_CATEGORY',
  FEATURED_MEMBERSHIP: 'FEATURED_MEMBERSHIP',
  FEATURED_PASS: 'FEATURED_PASS',
};

export const ITEM_TYPE_MAP = {
  GIFT_CARD: 'Gift Card',
  PASS: 'Pass',
  MEMBERSHIP: 'Membership',
  MEMBERSHIP_RESUME: 'Membership',
  PASS_OR_MEMBERSHIP: 'Pass/Membership',
  FEE: 'Fee',
  EVENT_BOOKING_TICKET: 'Ticket',
  EVENT_SERIES_TICKET: 'Multi-Day Ticket',
  EVENT_BOOKING_INVOICE: 'Deposit',
  PRODUCT: 'Product',
  PRICING_TIER: 'Pricing Tier',
  TIP: 'Tip',
  REVENUE_CATEGORY: 'Revenue Category',
  FEATURED_MEMBERSHIP: 'Featured Membership',
  FEATURED_PASS: 'Featured Pass',
};

export const ITEM_TYPE_PLURAL_MAP = {
  GIFT_CARD: 'Gift Cards',
  PASS: 'Passes',
  MEMBERSHIP: 'Memberships',
  MEMBERSHIP_RESUME: 'Memberships',
  PASS_OR_MEMBERSHIP: 'Passes/Memberships',
  FEE: 'Fees',
  EVENT_BOOKING_TICKET: 'Tickets',
  EVENT_SERIES_TICKET: 'Multi-Day Tickets',
  EVENT_BOOKING_INVOICE: 'Deposits',
  PRODUCT: 'Products',
  PRICING_TIER: 'Pricing Tiers',
  TIP: 'Tips',
  REVENUE_CATEGORY: 'Revenue Categories',
  FEATURED_MEMBERSHIP: 'Featured Memberships',
  FEATURED_PASS: 'Featured Passes',
};

export const getItemTypeName = (itemType) => (
  ITEM_TYPE_MAP[itemType] ?? null
);

export const getItemTypeNamePlural = (itemType) => (
  ITEM_TYPE_PLURAL_MAP[itemType] ?? null
);
