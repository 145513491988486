import formatDistance from './formatDistance';
import formatLong from './formatLong';
import formatRelative from './formatRelative';
import localize from './localize';
import match from './match';


const locale = {
  code: 'en-SG',
  formatDistance,
  formatLong,
  formatRelative,
  localize,
  match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1,
  },
};


export default locale;
