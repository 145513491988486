// eslint-disable-next-line arrow-body-style
const buildMatchPatternFn = (args) => {
  // eslint-disable-next-line func-names
  return function (string) {
    // eslint-disable-next-line prefer-rest-params
    const options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const matchResult = string.match(args.matchPattern);
    if (!matchResult) return null;
    const matchedString = matchResult[0];
    const parseResult = string.match(args.parsePattern);
    if (!parseResult) return null;
    let value = args.valueCallback ? args.valueCallback(parseResult[0]) : parseResult[0];
    value = options.valueCallback ? options.valueCallback(value) : value;
    const rest = string.slice(matchedString.length);

    return {
      value,
      rest,
    };
  };
};

export default buildMatchPatternFn;
