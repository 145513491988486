const findKey = (object, predicate) => {
  for (const key in object) {
    if (object.hasOwnProperty(key) && predicate(object[key])) {
      return key;
    }
  }
  return undefined;
};

const findIndex = (array, predicate) => {
  for (let key = 0; key < array.length; key++) {
    if (predicate(array[key])) {
      return key;
    }
  }
  return undefined;
};

// eslint-disable-next-line arrow-body-style
const buildMatchFn = (args) => {
  // eslint-disable-next-line func-names
  return function (string) {
    // eslint-disable-next-line prefer-rest-params
    const options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const { width } = options;
    const matchPattern = width && args.matchPatterns[width] || args.matchPatterns[args.defaultMatchWidth];
    const matchResult = string.match(matchPattern);
    if (!matchResult) {
      return null;
    }
    const matchedString = matchResult[0];
    const parsePatterns = width && args.parsePatterns[width] || args.parsePatterns[args.defaultParseWidth];
    const key = (
      Array.isArray(parsePatterns)
        ? findIndex(parsePatterns, (pattern) => pattern.test(matchedString))
        : findKey(parsePatterns, (pattern) => pattern.test(matchedString))
    );
    let value = args.valueCallback ? args.valueCallback(key) : key;
    value = options.valueCallback ? options.valueCallback(value) : value;
    const rest = string.slice(matchedString.length);

    return {
      value,
      rest,
    };
  };
};


export default buildMatchFn;
