import React from 'react';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';

import * as API from 'service/api';
import { getErrorMessage, somePropsChanged } from 'service/utility';

import { PicklistField } from '../../formFields';
import { Spinner } from '../../statusIndicators';


class RevenueCategoryFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      revenueCategoryPicklistValues: [],
      revenueCategoryId: (
        this.props.hasOwnProperty('revenueCategoryId') && this.props.revenueCategoryId !== 'null'
          ? this.props.revenueCategoryId === '@assigned' || this.props.revenueCategoryId === '@unassigned'
            ? this.props.revenueCategoryId
            : Number(this.props.revenueCategoryId)
          : 0
      ),
    };
  }

  componentDidMount() {
    this._isMounted = true;

    this.handleValueChange();
    this.loadRevenueCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['revenueCategoryId'])) {
      this.handleValueChange();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadRevenueCategories = async () => {
    try {
      const { data: { data: revenueCategories } } = await API.findRevenueCategory('page=1&pageSize=999');
      const revenueCategoryPicklistValues = [
        {
          label: 'All',
          value: 0,
        },
        {
          label: 'Assigned',
          value: '@assigned',
        },
        {
          label: 'Unassigned',
          value: '@unassigned',
        },
      ].concat(revenueCategories.map((revenueCategory) => ({
        label: revenueCategory.name,
        value: revenueCategory.id,
      })));

      if (this._isMounted) {
        this.setState({
          revenueCategoryPicklistValues,
          loading: false,
        });
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('API.findRevenueCategory error: ');
      console.log(errorMessage);

      toast.error(errorMessage);

      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }
  };

  getOwnFilterMap = () => ({
    revenueCategoryId: this.state.revenueCategoryId === 0 ? null : this.state.revenueCategoryId,
  });

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  handleRevenueCategoryChange = (update) => {
    if (update.hasOwnProperty('value')) {
      this.setState({ revenueCategoryId: update.value });
    }
  };


  render() {
    const { loading, revenueCategoryId, revenueCategoryPicklistValues } = this.state;

    return loading ? (
      <Spinner />
    ) : (
      <PicklistField
        fullWidth
        className="revenue-category-filter"
        label="Revenue Category"
        value={revenueCategoryId}
        onChange={this.handleRevenueCategoryChange}
        picklistValues={revenueCategoryPicklistValues}
      />
    );
  }
}

RevenueCategoryFilter.propTypes = {
  onValueChange: PropTypes.func,
  revenueCategoryId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

RevenueCategoryFilter.ownName = 'RevenueCategoryFilter';
RevenueCategoryFilter.ownFilters = ['revenueCategoryId'];


export default RevenueCategoryFilter;
