// eslint-disable-next-line arrow-body-style
const buildFormatLongFn = (args) => {
  // eslint-disable-next-line func-names
  return function () {
    // eslint-disable-next-line prefer-rest-params
    const options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const width = options.width ? String(options.width) : args.defaultWidth;

    return args.formats[width] || args.formats[args.defaultWidth];
  };
};


export default buildFormatLongFn;
