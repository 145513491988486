export const MySQL = {
  getQueryString: (searchString, page, pageSize, pageDirection, orderBy, order, filters) => {
    const queryParams = [];

    if (searchString) queryParams.push(`q=${encodeURIComponent(searchString)}`);
    queryParams.push(`page=${page}`);
    queryParams.push(`pageSize=${pageSize}`);
    if (orderBy) queryParams.push(`order=${orderBy.replace(/[^,]+/g, order)}`);
    if (orderBy) queryParams.push(`sort=${orderBy}`);
    Object.keys(filters).forEach((filterKey) => {
      const filterValue = filters[filterKey];

      if (filterValue !== null) {
        queryParams.push(`${filterKey}=${filterValue}`);
      }
    });

    return queryParams.join('&');
  },

  getData: (response) => response.data.data,

  getRowCount: (response) => response.data.pagination.rowCount,

  getPageCount: (response) => response.data.pagination.pageCount,

  hasPagination: () => true,
};

export const DynamoDB = {
  getQueryString: (searchString, page, pageSize, pageDirection, orderBy, order, filters, data) => {
    const queryParams = [];

    if (page !== 1 && data.length > 0) queryParams.push(`token=${data[0].updatedAt}`);
    queryParams.push(`limit=${pageSize}`);
    queryParams.push(`sortDir=${order}`);
    queryParams.push(`pageDir=${pageDirection ? 'ASC' : 'DESC'}`);
    Object.keys(filters).forEach((filterKey) => {
      const filterValue = filters[filterKey];

      if (filterValue !== null) {
        queryParams.push(`${filterKey}=${filterValue}`);
      }
    });

    return queryParams.join('&');
  },

  getData: (response) => response.data.data,

  getRowCount: (response, maxRowsEncountered) => maxRowsEncountered,

  getPageCount: (response, maxPageEncountered) => maxPageEncountered,

  hasPagination: () => false,
};
