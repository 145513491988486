import React from 'react';
import { PropTypes } from 'prop-types';
import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core';

import { isRequired } from 'service/utility/errorMessages';


const CustomButton = ({ insertText, btnLabel, textToInsert }) => (
  <button
    className="editor-toolbar-custom-button"
    type="button"
    onMouseDown={(e) => { e.preventDefault(); insertText(textToInsert); }}
  >
    {btnLabel}
  </button>
);
CustomButton.propTypes = {
  btnLabel: PropTypes.string.isRequired,
  insertText: PropTypes.func.isRequired,
  textToInsert: PropTypes.string.isRequired,
};


class RichTextField extends React.Component {
  constructor(props) {
    super(props);

    let editorState = EditorState.createEmpty();
    if (this.props.value) {
      const blocksFromHTML = htmlToDraft(this.props.value);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks, blocksFromHTML.entityMap
      );
      editorState = EditorState.createWithContent(state);
    }

    this.state = {
      blurred: false,
      editorState,
    };
  }

  componentDidMount() {
    const { onChange, autoFocus } = this.props;

    if (onChange) {
      const { editorState } = this.state;

      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const rawValue = draftToHtml(rawContentState);
      const value = rawValue || null;
      const error = this.validateSelf(value);

      if (error) {
        onChange({ error });
      }
    }

    if (autoFocus && this._ref) {
      this._ref.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.required !== this.props.required) {
      const { onChange } = this.props;

      if (onChange) {
        const { editorState } = this.state;

        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const rawValue = draftToHtml(rawContentState);
        const value = rawValue || null;
        const error = this.validateSelf(value);

        onChange({ error });
      }
    }
  }

  componentWillUnmount() {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  }

  validateSelf = (value) => {
    const { required, label, validate } = this.props;

    if (!value && required) return isRequired(label);

    return validate ? validate(value) : null;
  };

  handleLeaveFocus = (event, editorState) => {
    this.setState({
      blurred: true,
    });

    const { onChange } = this.props;

    if (onChange) {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const rawValue = draftToHtml(rawContentState).slice(0, -1);
      const value = rawValue === '<p></p>' ? '' : rawValue || '';
      const error = this.validateSelf(value);

      onChange({ value, error });
    }
  };

  handleChangeSelf = (editorState) => {
    this.setState({
      editorState,
    });

    const { onChange } = this.props;

    if (onChange) {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const rawValue = draftToHtml(rawContentState).slice(0, -1);
      const value = rawValue === '<p></p>' ? '' : rawValue || '';
      const error = this.validateSelf(value);

      onChange({ value, error });
    }
  };

  insertText = (text) => {
    if (this._ref) {
      this._ref.focus();
    }
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    const newContentState = Modifier.insertText(contentState, selectionState, text);
    const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');

    this.setState({ editorState: newEditorState }, () => {
      if (this._ref) {
        this._ref.focus();
      }
    });
  };

  render() {
    const { label, fullWidth, toolbar, error, richTextExtraButtons } = this.props;
    const { editorState, blurred } = this.state;

    const showError = blurred && Boolean(error);

    const customToolbarButtons = richTextExtraButtons.map((button, idx) => (
      <CustomButton
        key={idx}
        insertText={this.insertText}
        btnLabel={button.btnLabel}
        textToInsert={button.textToInsert}
      />
    ));

    return (
      <FormControl
        classes={{ root: 'richtext-field' }}
        margin="dense"
        fullWidth={fullWidth}
      >
        {label && (
          <InputLabel
            classes={{ root: 'input-label' }}
            shrink
            variant="outlined"
          >
            {label}
          </InputLabel>
        )}
        <Editor
          toolbar={toolbar}
          editorRef={(ref) => { this._ref = ref; }}
          editorState={editorState}
          onEditorStateChange={this.handleChangeSelf}
          onBlur={this.handleLeaveFocus}
          wrapperClassName="richtext-wrapper"
          editorClassName="richtext-editor"
          toolbarCustomButtons={customToolbarButtons || []}
        />
        {error && (
          <FormHelperText
            error={showError}
            variant="outlined"
            margin="dense"
            required
          >
            {error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

RichTextField.propTypes = {
  autoFocus: PropTypes.bool,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  richTextExtraButtons: PropTypes.array,
  toolbar: PropTypes.object,
  validate: PropTypes.func,
  value: PropTypes.string,
};

RichTextField.defaultProps = {
  autoFocus: false,
  error: '',
  fullWidth: false,
  required: false,
  richTextExtraButtons: [],
  toolbar: {
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'remove', 'history'],
  },
  value: '',
};


export default RichTextField;
