import { initAxiosRegion, initAxios, initAxiosDocs } from './axios';


// REGION
export const getRegion = (namespace) => initAxiosRegion().then(
  (axios) => axios.get(`/region?namespace=${namespace}`)
);

// LOCATIONS
export const fetchLocations = (query = '') => initAxios().then(
  (axios) => axios.get(`/locations?${query}`)
);
export const fetchLocation = (locationId) => initAxios().then(
  (axios) => axios.get(`/locations/${locationId}`)
);
export const putLocation = (data) => initAxios().then(
  (axios) => axios.put('/locations', data)
);

// STAFF
export const fetchStaffById = (staffId) => initAxios().then(
  (axios) => axios.get(`/staff/${staffId}`)
);
export const fetchStaff = (locationId) => initAxios().then(
  (axios) => axios.get(`/locations/${locationId}/staff`)
);
export const findStaff = (query = '') => initAxios().then(
  (axios) => axios.get(`/staff?${query}`)
);
export const postStaffLocations = (staffId, data) => initAxios().then(
  (axios) => axios.post(`/staff/${staffId}/locations`, data)
);
export const activateUser = (staffId) => initAxios().then(
  (axios) => axios.post(`/staff/${staffId}/activate`)
);
export const deactivateUser = (staffId) => initAxios().then(
  (axios) => axios.post(`/staff/${staffId}/deactivate`)
);
export const deleteStaff = (staffId) => initAxios().then(
  (axios) => axios.delete(`/staff/${staffId}`)
);
export const putStaff = (data) => initAxios().then(
  (axios) => axios.put('/staff', data)
);

// GIFT CARDS TYPES
export const findGiftCardType = (query = '') => initAxios().then(
  (axios) => axios.get(`/giftcardtypes?${query}`)
);
export const getGiftCardTypeById = (giftCardTypeId) => initAxios().then(
  (axios) => axios.get(`/giftcardtypes/${giftCardTypeId}`)
);
export const putGiftCardType = (data) => initAxios().then(
  (axios) => axios.put('/giftcardtypes', data)
);
export const postGiftCardTypeLocations = (giftCardTypeId, data) => initAxios().then(
  (axios) => axios.post(`/giftcardtypes/${giftCardTypeId}/locations`, data)
);
export const addGiftCardTypeLocation = (giftCardTypeId, locationId, data) => initAxios().then(
  (axios) => axios.put(`/giftcardtypes/${giftCardTypeId}/locations/${locationId}`, data)
);
export const deleteGiftCardTypeLocation = (giftCardTypeId, locationId) => initAxios().then(
  (axios) => axios.delete(`/giftcardtypes/${giftCardTypeId}/locations/${locationId}`)
);

// MEMBERSHIP TYPES
export const findMembershipType = (query = '') => initAxios().then(
  (axios) => axios.get(`/membershiptypes?${query}`)
);
export const getMembershipTypeById = (membershipTypeId) => initAxios().then(
  (axios) => axios.get(`/membershiptypes/${membershipTypeId}`)
);
export const putMembershipType = (data) => initAxios().then(
  (axios) => axios.put('/membershiptypes', data)
);
export const postMembershipTypeLocations = (membershipTypeId, data) => initAxios().then(
  (axios) => axios.post(`/membershiptypes/${membershipTypeId}/locations`, data)
);
export const addMembershipTypeLocation = (membershipTypeId, locationId) => initAxios().then(
  (axios) => axios.put(`/membershiptypes/${membershipTypeId}/locations/${locationId}`, {})
);
export const deleteMembershipTypeLocation = (membershipTypeId, locationId) => initAxios().then(
  (axios) => axios.delete(`/membershiptypes/${membershipTypeId}/locations/${locationId}`)
);

// PASS TYPES
export const findPassType = (query = '') => initAxios().then(
  (axios) => axios.get(`/passtypes?${query}`)
);
export const getPassTypeById = (passTypeId) => initAxios().then(
  (axios) => axios.get(`/passtypes/${passTypeId}`)
);
export const putPassType = (data) => initAxios().then(
  (axios) => axios.put('/passtypes', data)
);
export const postPassTypeLocations = (passTypeId, data) => initAxios().then(
  (axios) => axios.post(`/passtypes/${passTypeId}/locations`, data)
);
export const addPassTypeLocation = (passTypeId, locationId, data) => initAxios().then(
  (axios) => axios.put(`/passtypes/${passTypeId}/locations/${locationId}`, data)
);
export const deletePassTypeLocation = (passTypeId, locationId) => initAxios().then(
  (axios) => axios.delete(`/passtypes/${passTypeId}/locations/${locationId}`)
);

// PRODUCTS
export const findProducts = (query = '') => initAxios().then(
  (axios) => axios.get(`/products?${query}`)
);
export const getProductById = (productId) => initAxios().then(
  (axios) => axios.get(`/products/${productId}`)
);
export const putProduct = (data) => initAxios().then(
  (axios) => axios.put('/products', data)
);
export const postProductLocations = (productId, data) => initAxios().then(
  (axios) => axios.post(`/products/${productId}/locations`, data)
);
export const getInventoryForProduct = (productId, query = '') => initAxios().then(
  (axios) => axios.get(`/products/${productId}/inventory?${query}`)
);
export const resetProduct = (productId) => initAxios().then(
  (axios) => axios.delete(`/products/${productId}/reset`)
);

// PROMO CODES
export const findPromoCode = (query = '') => initAxios().then(
  (axios) => axios.get(`/promocodes?${query}`)
);
export const getPromoCodeById = (promoCodeId) => initAxios().then(
  (axios) => axios.get(`/promocodes/${promoCodeId}`)
);
export const postPromoCode = (data) => initAxios().then(
  (axios) => axios.post('/promocodes', data)
);
export const putPromoCode = (data) => initAxios().then(
  (axios) => axios.put('/promocodes', data)
);
export const getPromoCodeItems = (promoCodeId, query = '') => initAxios().then(
  (axios) => axios.get(`/promocodes/${promoCodeId}/items?${query}`)
);
export const addPromoCodeItems = (promoCodeId, data) => initAxios().then(
  (axios) => axios.post(`/promocodes/${promoCodeId}/items`, data)
);
export const removePromoCodeItems = (data) => initAxios().then(
  (axios) => axios.delete('/promocodes/items', { data })
);
export const updatePromoCodeItems = (data) => initAxios().then(
  (axios) => axios.put('/promocodes/items', data)
);
export const updatePromoCodeMembershipItems = (promoCodeId, data) => initAxios().then(
  (axios) => axios.put(`/promocodes/${promoCodeId}/items/membership`, data)
);
export const updatePromoCodeRevenueCategoryItems = (promoCodeId, data) => initAxios().then(
  (axios) => axios.put(`/promocodes/${promoCodeId}/items/revenuecategory`, data)
);

// VARIANT TYPES
export const findVariantType = (query = '') => initAxios().then(
  (axios) => axios.get(`/varianttypes?${query}`)
);
export const getVariantTypeById = (variantTypeId) => initAxios().then(
  (axios) => axios.get(`/varianttypes/${variantTypeId}`)
);
export const putVariantType = (data) => initAxios().then(
  (axios) => axios.put('/varianttypes', data)
);
export const deleteVariantType = (variantTypeId) => initAxios().then(
  (axios) => axios.delete(`/varianttypes/${variantTypeId}`)
);

// VARIANTS
export const findVariant = (variantTypeId, query = '') => initAxios().then(
  (axios) => axios.get(`/varianttypes/${variantTypeId}/variants?${query}`)
);
export const getVariantById = (variantTypeId, variantId) => initAxios().then(
  (axios) => axios.get(`/varianttypes/${variantTypeId}/variants/${variantId}`)
);
export const putVariant = (variantTypeId, data) => initAxios().then(
  (axios) => axios.put(`/varianttypes/${variantTypeId}/variants`, data)
);
export const deleteVariant = (variantTypeId, variantId) => initAxios().then(
  (axios) => axios.delete(`/varianttypes/${variantTypeId}/variants/${variantId}`)
);

// PRODUCT VARIANTS
export const getAllProductVariants = (productId, query = '') => initAxios().then(
  (axios) => axios.get(`/products/${productId}/variants?${query}`)
);
export const getProductVariant = (productId, variantId) => initAxios().then(
  (axios) => axios.get(`/products/${productId}/variants/${variantId}`)
);
export const putProductVariant = (productId, data) => initAxios().then(
  (axios) => axios.put(`/products/${productId}/variants`, data)
);
export const deleteProductVariant = (productId, variantId) => initAxios().then(
  (axios) => axios.delete(`/products/${productId}/variants/${variantId}`)
);
export const putProductSetup = (productId, data) => initAxios().then(
  (axios) => axios.put(`/products/${productId}/setup`, data)
);
export const postProductSetup = (productId, data) => initAxios().then(
  (axios) => axios.post(`/products/${productId}/setup`, data)
);
export const deleteProductSetup = (productId, data) => initAxios().then(
  (axios) => axios.delete(`/products/${productId}/setup`, { data })
);

// PRODUCT INVENTORY
export const getProductInventoryById = (productInventoryId) => initAxios().then(
  (axios) => axios.get(`/inventory/${productInventoryId}`)
);
export const putProductInventory = (data) => initAxios().then(
  (axios) => axios.put('inventory', data)
);

// INVENTORY LOGS
export const findInventoryLog = (query = '') => initAxios().then(
  (axios) => axios.get(`/inventorylogs?${query}`)
);
export const getInventoryLogById = (inventoryLogId) => initAxios().then(
  (axios) => axios.get(`/inventorylogs/${inventoryLogId}`)
);
export const putInventoryLog = (data) => initAxios().then(
  (axios) => axios.put('/inventorylogs', data)
);

// INVENTORY ADJUSTMENTS
export const postInventoryAdjustment = (data) => initAxios().then(
  (axios) => axios.post('/inventoryadjustment', data)
);
export const getInventoryAdjustmentById = (inventoryAdjustmentId) => initAxios().then(
  (axios) => axios.get(`/inventoryadjustment/${inventoryAdjustmentId}`)
);
export const getInventoryAdjustmentsForLog = (inventoryLogId) => initAxios().then(
  (axios) => axios.get(`/inventorylogs/${inventoryLogId}/adjustment`)
);
export const getAllInventoryAdjustments = () => initAxios().then(
  (axios) => axios.get('/inventoryadjustment')
);

// CERTIFICATIONS
export const findCertifications = (query = '') => initAxios().then(
  (axios) => axios.get(`/certifications?${query}`)
);
export const getCertificationById = (certificationId) => initAxios().then(
  (axios) => axios.get(`/certifications/${certificationId}`)
);
export const putCertification = (data) => initAxios().then(
  (axios) => axios.put('/certifications', data)
);
export const postCertificationLocations = (certificationId, data) => initAxios().then(
  (axios) => axios.post(`/certifications/${certificationId}/locations`, data)
);

// STAFF CERTIFICATIONS
export const getStaffCertifications = (staffId) => initAxios().then(
  (axios) => axios.get(`/staff/${staffId}/certifications`)
);
export const addStaffCertification = (staffId, data) => initAxios().then(
  (axios) => axios.post(`staff/${staffId}/certifications`, data)
);
export const deleteStaffCertification = (staffId, staffHasCertificationId) => initAxios().then(
  (axios) => axios.delete(`/staff/${staffId}/certifications/${staffHasCertificationId}`)
);
export const updateStaffCertification = (staffId, staffHasCertificationId, data) => initAxios().then(
  (axios) => axios.post(`/staff/${staffId}/certifications/${staffHasCertificationId}`, data)
);

// CUSTOMER CERTIFICATIONS
export const getCustomerCertifications = (customerId) => initAxios().then(
  (axios) => axios.get(`/customers/${customerId}/certifications`)
);
export const addCustomerCertification = (customerId, data) => initAxios().then(
  (axios) => axios.post(`customers/${customerId}/certifications`, data)
);
export const deleteCustomerCertification = (customerId, certificationId) => initAxios().then(
  (axios) => axios.delete(`/customers/${customerId}/certifications/${certificationId}`)
);
export const updateCustomerCertification = (customerId, certificationId, data) => initAxios().then(
  (axios) => axios.post(`/customers/${customerId}/certifications/${certificationId}`, data)
);

// STAFF CALENDARS
export const getStaffCalendars = (staffId) => initAxios().then(
  (axios) => axios.get(`/staff/${staffId}/calendar`)
);
export const putStaffCalendar = (staffId, data) => initAxios().then(
  (axios) => axios.put(`staff/${staffId}/calendar`, data)
);
export const deleteStaffCalendar = (staffId, data) => initAxios().then(
  (axios) => axios.delete(`staff/${staffId}/calendar`, { data })
);

// PAYMENT DEVICES
export const fetchDevicesByLocationId = (locationId) => initAxios().then(
  (axios) => axios.get(`/locations/${locationId}/devices`)
);
export const getDeviceById = (locationId, deviceId) => initAxios().then(
  (axios) => axios.get(`/locations/${locationId}/devices/${deviceId}`)
);
export const putDevice = (locationId, data) => initAxios().then(
  (axios) => axios.put(`/locations/${locationId}/devices`, data)
);

// CASH DRAWER
export const getCurrentRegister = (locationId) => initAxios().then(
  (axios) => axios.get(`/locations/${locationId}/register/current`)
);
export const getRegisterById = (locationId, registerId) => initAxios().then(
  (axios) => axios.get(`/locations/${locationId}/register/${registerId}`)
);
export const getRegisterOrders = (locationId, registerId) => initAxios().then(
  (axios) => axios.get(`/locations/${locationId}/register/${registerId}/orders`)
);
export const getRegistersByLocation = (locationId, query = '') => initAxios().then(
  (axios) => axios.get(`/locations/${locationId}/register?${query}`)
);
export const startRegister = (locationId, data) => initAxios().then(
  (axios) => axios.post(`/locations/${locationId}/register/start`, data)
);
export const updateRegister = (locationId, registerId, data) => initAxios().then(
  (axios) => axios.put(`/locations/${locationId}/register/${registerId}`, data)
);
export const registerPayIn = (locationId, data) => initAxios().then(
  (axios) => axios.post(`/locations/${locationId}/register/payin`, data)
);
export const registerPayOut = (locationId, data) => initAxios().then(
  (axios) => axios.post(`/locations/${locationId}/register/payout`, data)
);
export const endRegister = (locationId, registerId, data) => initAxios().then(
  (axios) => axios.post(`/locations/${locationId}/register/${registerId}/end`, data)
);
export const finalRegister = (locationId, registerId, data) => initAxios().then(
  (axios) => axios.post(`/locations/${locationId}/register/${registerId}/final`, data)
);

// DOCUMENT TEMPLATES
export const getTemplates = () => initAxiosDocs().then(
  (axios) => axios.get('/waiver/template?showDeactivated=true')
);
export const getTemplate = (uuid) => initAxiosDocs().then(
  (axios) => axios.get(`/waiver/template/${uuid}`)
);
export const deleteTemplate = (uuid) => initAxiosDocs().then(
  (axios) => axios.delete(`/waiver/template/${uuid}`)
);
export const postTemplate = (data) => initAxiosDocs().then(
  (axios) => axios.post('/waiver/template', data)
);
export const previewTemplate = (uuid) => initAxiosDocs().then(
  (axios) => axios.get(`/waiver/template/${uuid}/preview`)
);

// REMINDER TEMPLATES
export const getReminderTemplates = (query = '') => initAxios().then(
  (axios) => axios.get(`/messagescheduler/templates?${query}`)
);
export const getReminderTemplateById = (id) => initAxios().then(
  (axios) => axios.get(`/messagescheduler/templates/${id}`)
);
export const putReminderTemplate = (data) => initAxios().then(
  (axios) => axios.put('/messagescheduler/templates', data)
);
export const getBookingReminderTemplates = (bookingId, query = '') => initAxios().then(
  (axios) => axios.get(`/bookings/${bookingId}/templates?${query}`)
);
export const getEventReminderTemplates = (eventId, query = '') => initAxios().then(
  (axios) => axios.get(`/events/${eventId}/templates?${query}`)
);
export const upsertEventReminderTemplate = (eventId, data) => initAxios().then(
  (axios) => axios.put(`/events/${eventId}/templates`, data)
);
export const upsertBookingReminderTemplate = (bookingId, data) => initAxios().then(
  (axios) => axios.put(`/bookings/${bookingId}/templates`, data)
);
export const deleteBookingReminderTemplate = (bookingId, templateId) => initAxios().then(
  (axios) => axios.delete(`/bookings/${bookingId}/templates/${templateId}`)
);
export const deleteEventReminderTemplate = (eventId, templateId) => initAxios().then(
  (axios) => axios.delete(`/events/${eventId}/templates/${templateId}`)
);
export const getBookingReminderTemplatePreview = (bookingId, messageTemplateId) => initAxios().then(
  (axios) => axios.get(`/bookings/${bookingId}/templates/${messageTemplateId}/preview`)
);
export const getEventReminderTemplatePreview = (eventId, messageTemplateId) => initAxios().then(
  (axios) => axios.get(`/events/${eventId}/templates/${messageTemplateId}/preview`)
);
export const sendBookingReminderTemplateInNextBatch = (bookingId, templateId) => initAxios().then(
  (axios) => axios.post(`/bookings/${bookingId}/templates/${templateId}/send`)
);
export const sendEventReminderTemplateInNextBatch = (eventId, templateId) => initAxios().then(
  (axios) => axios.post(`/events/${eventId}/templates/${templateId}/send`)
);
export const updateEventOptions = (eventId, data) => initAxios().then(
  (axios) => axios.put(`/events/${eventId}/options`, data)
);
export const updateBookingOptions = (bookingId, data) => initAxios().then(
  (axios) => axios.put(`/bookings/${bookingId}/options`, data)
);

// TICKET TYPES
export const findTicketType = (query = '') => initAxios().then(
  (axios) => axios.get(`/tickettypes?${query}`)
);
export const getTicketTypeById = (ticketTypeId) => initAxios().then(
  (axios) => axios.get(`/tickettypes/${ticketTypeId}`)
);
export const putTicketType = (data) => initAxios().then(
  (axios) => axios.put('/tickettypes', data)
);

// PRICING TIERS
export const findPricingTier = (query = '') => initAxios().then(
  (axios) => axios.get(`/pricingTiers?${query}`)
);
export const getPricingTierById = (tierId) => initAxios().then(
  (axios) => axios.get(`/pricingTiers/${tierId}`)
);
export const putPricingTier = (data) => initAxios().then(
  (axios) => axios.put('/pricingTiers', data)
);
export const deletePricingTier = (tierId) => initAxios().then(
  (axios) => axios.delete(`/pricingTiers/${tierId}`)
);

// ACTIVITIES
export const findActivity = (query = '') => initAxios().then(
  (axios) => axios.get(`/activities?${query}`)
);
export const getActivityById = (activityId) => initAxios().then(
  (axios) => axios.get(`/activities/${activityId}`)
);
export const putActivity = (data) => initAxios().then(
  (axios) => axios.put('/activities', data)
);
export const postActivityLocations = (activityId, data) => initAxios().then(
  (axios) => axios.post(`/activities/${activityId}/locations`, data)
);

// REVENUE CATEGORIES
export const findRevenueCategory = (query = '') => initAxios().then(
  (axios) => axios.get(`/revenuecategory?${query}`)
);
export const getRevenueCategoryById = (revenueCategoryId) => initAxios().then(
  (axios) => axios.get(`/revenuecategory/${revenueCategoryId}`)
);
export const putRevenueCategory = (data) => initAxios().then(
  (axios) => axios.put('/revenuecategory', data)
);
export const deleteRevenueCategory = (revenueCategoryId) => initAxios().then(
  (axios) => axios.delete(`/revenuecategory/${revenueCategoryId}`)
);
export const massAssignRevenueCategory = (revenueCategoryId, modelName, data) => initAxios().then(
  (axios) => axios.put(`/revenuecategory/${revenueCategoryId}/${modelName}`, data)
);

// REVENUE CATEGORY REPORTS
export const findRevenueCategoryReport = (query = '') => initAxios().then(
  (axios) => axios.get(`/rcreports?${query}`)
);
export const createRevenueCategoryReport = (data) => initAxios().then(
  (axios) => axios.post('/rcreports/create', data)
);
export const getRevenueCategoryReport = (revenueCategoryReportId) => initAxios().then(
  (axios) => axios.get(`/rcreports/${revenueCategoryReportId}`)
);
export const putRevenueCategoryReport = (revenueCategoryReportId, data) => initAxios().then(
  (axios) => axios.put(`/rcreports/${revenueCategoryReportId}`, data)
);
export const deleteRevenueCategoryReport = (revenueCategoryReportId) => initAxios().then(
  (axios) => axios.delete(`/rcreports/${revenueCategoryReportId}`)
);
export const postCreateRevenueCategoryCashDrawerReport = (registerId) => initAxios().then(
  (axios) => axios.post(`/rcreports/cashdrawer/${registerId}`)
);

// QUICKBOOKS CONNECTIONS
export const findQBConnection = (query = '') => initAxios().then(
  (axios) => axios.get(`/quickbooks/connection?${query}`)
);
export const getQBConnection = (qbConnectionId) => initAxios().then(
  (axios) => axios.get(`/quickbooks/connection/${qbConnectionId}`)
);
export const putQBConnection = (data) => initAxios().then(
  (axios) => axios.put('/quickbooks/connection', data)
);
export const deleteQBConnection = (qbConnectionId) => initAxios().then(
  (axios) => axios.delete(`/quickbooks/connection/${qbConnectionId}`)
);
export const putQBConnectionLocations = (qbConnectionId, data) => initAxios().then(
  (axios) => axios.put(`/quickbooks/connection/${qbConnectionId}/locations`, data)
);
export const getQBConnectionMapping = () => initAxios().then(
  (axios) => axios.get('/quickbooks/connection/mapping')
);
export const getQBConnectionAccounts = (qbConnectionId, query = '') => initAxios().then(
  (axios) => axios.get(`/quickbooks/${qbConnectionId}/paginatedAccounts?${query}`)
);
export const createLedger = (data) => initAxios().then(
  (axios) => axios.post('/ledgers', data)
);
export const upsertLedger = (data) => initAxios().then(
  (axios) => axios.put('/ledgers', data)
);
export const upsertLedgerAccounts = (ledgerId, data) => initAxios().then(
  (axios) => axios.put(`/ledgers/${ledgerId}/accounts`, data)
);
export const upsertLedgerMappings = (ledgerId, data) => initAxios().then(
  (axios) => axios.put(`/ledgers/${ledgerId}/mappings`, data)
);
export const deleteMapping = (ledgerId) => initAxios().then(
  (axios) => axios.delete(`/ledgers/${ledgerId}/mappings`)
);
export const getLedgerJournalEntries = (qbConnectionId, query = '') => initAxios().then(
  (axios) => axios.get(`/quickbooks/connection/${qbConnectionId}/ledgerJournalEntries?${query}`)
);
export const createLedgerJournalEntry = (qbConnectionId, rcReportId) => initAxios().then(
  (axios) => axios.post(`/quickbooks/${qbConnectionId}/journalEntry/${rcReportId}`)
);
export const deleteLedgerJournalEntry = (qbConnectionId, rcReportId) => initAxios().then(
  (axios) => axios.delete(`/quickbooks/${qbConnectionId}/journalEntry/${rcReportId}`)
);
export const getQBConnectionClasses = (qbConnectionId, query = '') => initAxios().then(
  (axios) => axios.get(`/quickbooks/${qbConnectionId}/classes?${query}`)
);
export const getQBConnectionClassMapping = (qbConnectionId) => initAxios().then(
  (axios) => axios.get(`/quickbooks/connection/${qbConnectionId}/locations/classes`)
);
export const putQBConnectionClassMapping = (qbConnectionId, data) => initAxios().then(
  (axios) => axios.put(`/quickbooks/connection/${qbConnectionId}/locations/classes`, data)
);
export const getQBConnectionClasses2 = (qbConnectionId) => initAxios().then(
  (axios) => axios.get(`/rcreports/quickbooks/${qbConnectionId}/classes`)
);

// FOR OWNER
export const getMemberships = (query = '') => initAxios().then(
  (axios) => axios.get(`/memberships?${query}`)
);
// Membership Logs
export const getMembershipsLogs = (query = '') => initAxios().then(
  (axios) => axios.get(`/memberships/logs?${query}`)
);
export const getMembershipsLogsById = (id) => initAxios().then(
  (axios) => axios.get(`/memberships/logs/${id}`)
);
export const getCustomerMembershipLogs = (customerId, membershipId, query = '') => initAxios().then(
  (axios) => axios.get(`/customers/${customerId}/memberships/${membershipId}/logs?${query}`)
);
export const getCustomerMembershipLogById = (customerId, membershipId, logId) => initAxios().then(
  (axios) => axios.get(`/customers/${customerId}/memberships/${membershipId}/logs/${logId}`)
);

// STAFF ROLES
export const findStaffRoles = (query = '') => initAxios().then(
  (axios) => axios.get(`/roles?${query}`)
);
export const getStaffRolesById = (roleId) => initAxios().then(
  (axios) => axios.get(`/roles/${roleId}`)
);
export const putStaffRole = (data) => initAxios().then(
  (axios) => axios.put('/roles', data)
);
export const putStaffRolePermissions = (roleId, data) => initAxios().then(
  (axios) => axios.put(`/roles/${roleId}/permissions`, data)
);

// PERMISSIONS
export const findPermissions = (query = '') => initAxios().then(
  (axios) => axios.get(`/permissions?${query}`)
);

// ISSUE TYPES
export const findIssueType = (query = '') => initAxios().then(
  (axios) => axios.get(`/support/issuetypes?${query}`)
);
export const getIssueTypeById = (issueTypeId) => initAxios().then(
  (axios) => axios.get(`/support/issuetypes/${issueTypeId}`)
);
export const putIssueType = (data, issueTypeId) => initAxios().then(
  (axios) => axios.put(`/support/issuetypes/${issueTypeId}`, data)
);
export const postIssueType = (data) => initAxios().then(
  (axios) => axios.post('/support/issuetypes', data)
);

// CHAT
export const findChats = (query = '') => initAxios().then(
  (axios) => axios.get(`/support/chats?${query}`)
);
export const getChatById = (chatId) => initAxios().then(
  (axios) => axios.get(`/support/chats/${chatId}`)
);
export const postChat = (chatId, data) => initAxios().then(
  (axios) => axios.post(`/support/chats/${chatId}/messages`, data)
);
export const closeChat = (chatId, data) => initAxios().then(
  (axios) => axios.put(`/support/chats/${chatId}/close`, data)
);

// MERCHANT TRACK
export const getMerchantTrackAuthKey = (locationId) => initAxios().then(
  (axios) => axios.get(`/merchanttrack/${locationId}/authkey`)
);

// QUESTIONS
export const findQuestions = (query = '') => initAxios().then(
  (axios) => axios.get(`/question?${query}`)
);
export const postQuestion = (data) => initAxios().then(
  (axios) => axios.post('/question', data)
);
export const getQuestionById = (questionId) => initAxios().then(
  (axios) => axios.get(`/question/${questionId}`)
);
export const putQuestion = (data, questionId) => initAxios().then(
  (axios) => axios.put(`/question/${questionId}`, data)
);

// FORM TEMPLATES
export const findFormTemplates = (query = '') => initAxios().then(
  (axios) => axios.get(`/form?${query}`)
);
export const postFormTemplate = (data) => initAxios().then(
  (axios) => axios.post('/form', data)
);
export const getFormTemplateById = (formId) => initAxios().then(
  (axios) => axios.get(`/form/${formId}`)
);
export const getFormTemplateByFormVersionId = (formId, formversionId) => initAxios().then(
  (axios) => axios.get(`/form/${formId}/version/${formversionId}`)
);
export const putFormTemplate = (data, formId) => initAxios().then(
  (axios) => axios.put(`/form/${formId}`, data)
);
export const putFormTemplateQuestions = (data, formId, formVersionId) => initAxios().then(
  (axios) => axios.put(`/form/${formId}/version/${formVersionId}/questions`, data)
);
export const publishFormVersion = (formId, formVersionId) => initAxios().then(
  (axios) => axios.post(`/form/${formId}/version/${formVersionId}/publish`)
);
export const finalizeFormVersion = (formId, formVersionId) => initAxios().then(
  (axios) => axios.post(`/form/${formId}/version/${formVersionId}/finalize`)
);
export const createNewFormVersion = (formId, data) => initAxios().then(
  (axios) => axios.post(`/form/${formId}/create`, data)
);
export const emailForms = (data) => initAxios().then(
  (axios) => axios.post('/form/email', data)
);

// SUBMISSIONS
export const getFormSubmissionsByEventId = (eventId, query = '') => initAxios().then(
  (axios) => axios.get(`/submission/event/${eventId}?${query}`)
);
export const getFormSubmissionsByBookingId = (bookingId, query = '') => initAxios().then(
  (axios) => axios.get(`/submission/booking/${bookingId}?${query}`)
);
export const getFormSubmissionsByFormId = (formId, query = '') => initAxios().then(
  (axios) => axios.get(`/submission/form/${formId}?${query}`)
);
export const getFormSubmissionBySubmissionId = (submissionId) => initAxios().then(
  (axios) => axios.get(`/submission/${submissionId}`)
);

// STORE
export const fetchAllOrders = (query = '') => initAxios().then(
  (axios) => axios.get(`/orders?${query}`)
);
export const getProductByBarcodeId = (barcodeId, locationId) => initAxios().then(
  (axios) => axios.get(`/barcode/${barcodeId}?locationId=${locationId}`)
);
export const searchGiftCards = (query = '') => initAxios().then(
  (axios) => axios.get(`/giftcards?${query}`)
);
export const getGiftCard = (giftCardId) => initAxios().then(
  (axios) => axios.get(`/giftcards/${giftCardId}`)
);
export const getGiftCardByExternalId = (externalId) => initAxios().then(
  (axios) => axios.get(`/giftcards/externalid/${externalId}`)
);
export const putGiftCard = (giftCardId, data) => initAxios().then(
  (axios) => axios.put(`/giftcards/${giftCardId}`, data)
);
export const deleteGiftCard = (giftCardId) => initAxios().then(
  (axios) => axios.delete(`/giftcards/${giftCardId}`)
);
export const sendGiftCardEmail = (giftCardId) => initAxios().then(
  (axios) => axios.post('/emails/gift-card', { giftCardId })
);

// NOTES
export const putNote = (resource, resourceId, data) => initAxios().then(
  (axios) => axios.put(`/notes/${resource}/${resourceId}`, data)
);
export const getNotesOnActivity = (resource, resourceId) => initAxios().then(
  (axios) => axios.get(`/notes/activity/${resource}/${resourceId}`)
);
export const getNotes = (resource, resourceId, query = '') => initAxios().then(
  (axios) => axios.get(`/notes/${resource}/${resourceId}?${query}`)
);
export const getNoteById = (resource, resourceId, noteId) => initAxios().then(
  (axios) => axios.get(`/notes/${resource}/${resourceId}/${noteId}`)
);
export const deleteNote = (resource, resourceId, noteId) => initAxios().then(
  (axios) => axios.delete(`/notes/${resource}/${resourceId}/${noteId}`)
);

// CUSTOMERS
export const searchCustomers = (query = '') => initAxios().then(
  (axios) => axios.get(`/customers?${query}`)
);
export const fetchCustomer = (customerId) => initAxios().then(
  (axios) => axios.get(`/customers/${customerId}`)
);
export const putCustomer = (payload) => initAxios().then(
  (axios) => axios.put('/customers', payload)
);
export const mergeCustomerAccounts = (primaryCustomerId, duplicateCustomerId) => initAxios().then(
  (axios) => axios.put(`/customers/${primaryCustomerId}/merge`, [duplicateCustomerId])
);
export const getCustomerLocations = (customerId) => initAxios().then(
  (axios) => axios.get(`/customers/${customerId}/locations`)
);
export const postCustomerLocations = (customerId, payload) => initAxios().then(
  (axios) => axios.post(`/customers/${customerId}/locations`, payload)
);

// CUSTOMERS RESERVATIONS
export const getCustomerReservations = (customerId, query = '') => initAxios().then(
  (axios) => axios.get(`/customers/${customerId}/reservations?${query}`)
);

// GROUPS
export const searchGroups = (query = '') => initAxios().then(
  (axios) => axios.get(`/groups?${query}`)
);
export const upsertGroup = (payload) => initAxios().then(
  (axios) => axios.put('/groups', payload)
);
export const groupCustomerAction = (groupId, customerId, action, payload) => initAxios().then(
  (axios) => axios.post(`/groups/${groupId}/members/customers/${customerId}/${action}`, payload)
);
export const getGroupById = (groupId) => initAxios().then(
  (axios) => axios.get(`/groups/${groupId}`)
);
export const getAllGroupMembers = (groupId) => initAxios().then(
  (axios) => axios.get(`/groups/${groupId}/members`)
);
export const postGroupLocations = (groupId, data) => initAxios().then(
  (axios) => axios.post(`/groups/${groupId}/locations`, data)
);
export const getGroupMembers = (query = '') => initAxios().then(
  (axios) => axios.get(`/groupmembers?${query}`)
);

// ORDERS
export const postNewOrder = (payload) => initAxios().then(
  (axios) => axios.post('/orders/new', payload)
);
export const postOrderSale = (orderId, payload) => initAxios().then(
  (axios) => axios.post(`/orders/${orderId}/sale`, payload)
);
export const fetchOrder = (orderId) => initAxios().then(
  (axios) => axios.get(`/orders/${orderId}`)
);
export const fetchOrders = (customerId, query = '') => initAxios().then(
  (axios) => axios.get(`/customers/${customerId}/orders?${query}`)
);
export const refundOrder = (orderId, payload) => initAxios().then(
  (axios) => axios.post(`/orders/${orderId}/refund`, payload)
);
export const cancelOrder = (orderId, payload) => initAxios().then(
  (axios) => axios.post(`/orders/${orderId}/cancel`, payload)
);
export const getOrderRefundOptions = (orderId) => initAxios().then(
  (axios) => axios.get(`/orders/${orderId}/refundoptions`)
);
export const fixOrder = (orderId, payload) => initAxios().then(
  (axios) => axios.post(`/orders/${orderId}/fix`, payload)
);

// TRANSACTIONS
export const getTransactionById = (transactionId) => initAxios().then(
  (axios) => axios.get(`/transactions/${transactionId}`)
);
export const fetchTransactions = (query = '') => initAxios().then(
  (axios) => axios.get(`/transactions?${query}`)
);
export const voidTransaction = (transactionId, payload) => initAxios().then(
  (axios) => axios.post(`/transactions/${transactionId}/void`, payload)
);
export const refreshTransactionByUUID = (transactionUUID) => initAxios().then(
  (axios) => axios.get(`/transactions/${transactionUUID}/refresh`)
);
export const refreshTransactions = (payload) => initAxios().then(
  (axios) => axios.post('/transactions/refresh', payload)
);
export const deleteTransaction = (transactionId) => initAxios().then(
  (axios) => axios.delete(`/transactions/${transactionId}`)
);

// PAYMENT CARDS
export const fetchCardsOnCustomer = ({ customerId, merchant, all }) => initAxios().then((axios) => {
  const paramString = new URLSearchParams({
    customerId,
    ...(merchant ? { merchant } : {}),
    ...(all ? { isActive: 'all' } : {}),
  }).toString();

  return axios.get(`/customers/${customerId}/paymentcards?${paramString}`);
});
export const createPaymentCard = (customerId, payload) => initAxios().then(
  (axios) => axios.post(`/customers/${customerId}/paymentcards`, payload)
);
export const checkCardTokenStatus = (customerId, token) => initAxios().then(
  (axios) => axios.get(`/customers/${customerId}/paymentcards/status/${token}`)
);
export const putPaymentCard = (customerId, payload) => initAxios().then(
  (axios) => axios.put(`/customers/${customerId}/paymentcards`, payload)
);
export const deletePaymentCard = (customerId, cardId) => initAxios().then(
  (axios) => axios.delete(`/customers/${customerId}/paymentcards/${cardId}`)
);
export const getMerchantPrivateKey = (locationId, query = '') => initAxios().then(
  (axios) => axios.get(`/paymentcards/locations/${locationId}/initsave?${query}`)
);
export const addPaymentCard = (payload) => initAxios().then(
  (axios) => axios.put('/paymentcards', payload)
);

// BOOKINGS
export const getCustomerBookings = (customerId, query = '') => initAxios().then(
  (axios) => axios.get(`/customers/${customerId}/bookings?${query}`)
);

// CHECKINS
export const findCheckins = (params = [], query = '') => initAxios().then(
  (axios) => {
    const paramsString = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
    const allParamsString = `${paramsString || query ? '?' : ''}${paramsString}${paramsString && query ? '&' : ''}${query}`;

    return axios.get(`/checkins${allParamsString}`);
  }
);
export const getCheckinById = (checkinId) => initAxios().then(
  (axios) => axios.get(`checkins/${checkinId}`)
);
export const postCheckin = (locationId, payload) => initAxios().then(
  (axios) => axios.post(`/checkins/location/${locationId}`, payload)
);
export const getAssociatedCustomersByParentId = (customerId) => initAxios().then(
  (axios) => axios.get(`/checkins/customer/${customerId}/associated`)
);
export const getAvailableCheckins = (locationId, customerId) => initAxios().then(
  (axios) => axios.get(`/checkins/locations/${locationId}/customers/${customerId}/available`)
);
export const getAvailableCheckinForBooking = (bookingId, customerId) => initAxios().then(
  (axios) => axios.get(`/checkins/bookings/${bookingId}/customers/${customerId}/available`)
);
export const approveCheckin = (checkinId, payload) => initAxios().then(
  (axios) => axios.post(`/checkins/${checkinId}/approved`, payload)
);
export const cancelCheckin = (checkinId, payload) => initAxios().then(
  (axios) => axios.post(`/checkins/${checkinId}/cancelled`, payload)
);
export const voidCheckin = (checkinId, payload) => initAxios().then(
  (axios) => axios.post(`/checkins/${checkinId}/void`, payload)
);
export const quickCheckinCustomerWithTicket = (locationId, customerId, ticketUUID) => initAxios().then(
  (axios) => axios.post(`/checkins/quick/${ticketUUID}`, { type: 'ticket', locationId, customerId, status: 'approved' })
);
export const quickCheckinCustomerToGA = (locationId, customerId) => initAxios().then(
  (axios) => axios.post(`/checkins/quick/${customerId}`, { type: 'customer', locationId })
);
export const quickCheckinCustomerWithBarcode = (locationId, barcodeId) => initAxios().then(
  (axios) => axios.post(`/checkins/barcode/${barcodeId}`, { type: 'customer', locationId, status: 'pending' })
);
export const getCheckinsByPlanType = (planType, planId, query = '') => initAxios().then(
  (axios) => axios.get(`/checkins?${planType}=${planId}&${query}`)
);

// MEMBERSHIPS
export const fetchCustomerMemberships = (customerId, locationId) => initAxios().then(
  (axios) => axios.get(`/customers/${customerId}/memberships?type=assigned,owned${locationId ? `&location=${locationId}` : ''}`)
);
export const fetchCustomerMembership = (customerId, membershipId) => initAxios().then(
  (axios) => axios.get(`/customers/${customerId}/memberships/${membershipId}`)
);
export const fetchCustomerAssignedMemberships = (customerId, locationId, filterType) => initAxios().then(
  (axios) => axios.get(`/customers/${customerId}/memberships?type=assigned&filterStatus=${filterType}${locationId ? `&location=${locationId}` : ''}`)
);
export const fetchCustomerOwnedPMemberships = (customerId, locationId, filterType) => initAxios().then(
  (axios) => axios.get(`/customers/${customerId}/memberships?type=owned&filterStatus=${filterType}${locationId ? `&location=${locationId}` : ''}`)
);
export const cancelMembership = (membershipId, payload) => initAxios().then(
  (axios) => axios.post(`/memberships/${membershipId}/status/cancel`, payload)
);
export const activateMembership = (membershipId, payload) => initAxios().then(
  (axios) => axios.post(`/memberships/${membershipId}/status/activate`, payload)
);
export const placeMembershipOnHold = (membershipId, payload) => initAxios().then(
  (axios) => axios.post(`/memberships/${membershipId}/status/hold`, payload)
);
export const upsertMembership = (customerId, membershipId, payload) => initAxios().then(
  (axios) => axios.put(`/customers/${customerId}/memberships/${membershipId}`, payload)
);
export const calculateMembership = (membershipId) => initAxios().then(
  (axios) => axios.get(`/memberships/${membershipId}/calculate/resume`)
);
export const resumeMembership = (membershipId, payload) => initAxios().then(
  (axios) => axios.post(`/memberships/${membershipId}/status/resume`, payload)
);

// PASSES
export const fetchCustomerPasses = (customerId, locationId) => initAxios().then(
  (axios) => axios.get(`/customers/${customerId}/passes?type=assigned,owned${locationId ? `&location=${locationId}` : ''}`)
);
export const fetchCustomerPass = (customerId, passId) => initAxios().then(
  (axios) => axios.get(`/customers/${customerId}/passes/${passId}`)
);
export const fetchCustomerAssignedPasses = (customerId, locationId, filterType) => initAxios().then(
  (axios) => axios.get(`/customers/${customerId}/passes?type=assigned&filterStatus=${filterType}${locationId ? `&location=${locationId}` : ''}`)
);
export const fetchCustomerOwnedPasses = (customerId, locationId, filterType) => initAxios().then(
  (axios) => axios.get(`/customers/${customerId}/passes?type=owned&filterStatus=${filterType}${locationId ? `&location=${locationId}` : ''}`)
);
export const upsertCustomerPass = (customerId, passId, payload) => initAxios().then(
  (axios) => axios.put(`/customers/${customerId}/passes/${passId}`, payload)
);
export const getPassByUUID = (passUUID) => initAxios().then(
  (axios) => axios.get(`/passes/${passUUID}`)
);
export const upsertPass = (payload) => initAxios().then(
  (axios) => axios.put('/passes', payload)
);
export const cancelPass = (passId) => initAxios().then(
  (axios) => axios.post(`/passes/${passId}/cancel`, { type: 'void' })
);
export const reactivatePass = (passId) => initAxios().then(
  (axios) => axios.post(`/passes/${passId}/reactivate`)
);

// WAIVERS
export const fetchWaivers = () => initAxios().then(
  (axios) => axios.get('/waivers')
);
export const putWaiver = (payload) => initAxios().then(
  (axios) => axios.put('/waivers', payload)
);
export const fetchWaiver = (waiverId) => initAxios().then(
  (axios) => axios.get(`/waivers/${waiverId}`)
);
export const fetchWaiversOnCustomer = (customerId, query = '') => initAxios().then(
  (axios) => axios.get(`/customers/${customerId}/waivers?${query}`)
);
export const deactivateWaiverOnCustomer = (customerId, waiverId) => initAxios().then(
  (axios) => axios.delete(`/customers/${customerId}/waivers/${waiverId}`)
);
export const getWaiverDownloadURL = (waiverUUID) => initAxiosDocs().then(
  (axios) => axios.get(`/waiver/${waiverUUID}/file`)
);
export const emailWaiver = (payload) => initAxios().then(
  (axios) => axios.post('/waivers/email', payload)
);

// NOTES
export const putCustomerNote = (customerId, payload) => putNote('customer', customerId, payload);
export const getCustomerNotesOnActivity = (customerId) => getNotesOnActivity('customer', customerId);
export const getCustomerNotes = (customerId, query = '') => getNotes('customer', customerId, query);
export const getCustomerNoteById = (customerId, noteId) => getNoteById('customer', customerId, noteId);
export const deleteCustomerNote = (customerId, noteId) => deleteNote('customer', customerId, noteId);

// GIFT CARDS
export const getCustomerGiftCards = (customerId, query = '') => initAxios().then(
  (axios) => axios.get(`/customers/${customerId}/giftcards?${query}`)
);
export const getCustomerGiftCardById = (customerId, giftCardId) => initAxios().then(
  (axios) => axios.get(`/customers/${customerId}/giftcards/${giftCardId}`)
);

export const sendPKPass = (payload) => initAxios().then(
  (axios) => axios.post('/emails/pkpass', payload)
);
export const sendEmailReceipt = (payload) => initAxios().then(
  (axios) => axios.post('/emails/receipt', payload)
);

// STORE CREDIT
export const getCustomerStoreCredit = (customerId) => initAxios().then(
  (axios) => axios.get(`/storecredit/customers/${customerId}`)
);
export const getStoreCreditTransactions = (storeCreditId, query = '') => initAxios().then(
  (axios) => axios.get(`/storecredit/${storeCreditId}/transactions?${query}`)
);
export const adjustStoreCredit = (customerId, payload) => initAxios().then(
  (axios) => axios.post(`/storecredit/customers/${customerId}/adjustment`, payload)
);
export const convertGiftCard = (customerId, payload) => initAxios().then(
  (axios) => axios.post(`/storecredit/customers/${customerId}/giftcards`, payload)
);

// POINTS
export const adjustPoints = (customerId, payload) => initAxios().then(
  (axios) => axios.post(`/points/customers/${customerId}/adjustment`, payload)
);
export const getCustomerPoints = (customerId) => initAxios().then(
  (axios) => axios.get(`/points/customers/${customerId}`)
);
export const getPointsById = (pointsLogId) => initAxios().then(
  (axios) => axios.get(`/pointslog/${pointsLogId}`)
);
export const queryPointsLog = (pointsId, query = '') => initAxios().then(
  (axios) => axios.get(`/points/${pointsId}/log?${query}`)
);
export const queryRedeemableItems = (itemType, query = '') => initAxios().then(
  (axios) => axios.get(`/points/items/redeem?itemType=${itemType}&${query}`)
);
export const redeemPoints = (payload) => initAxios().then(
  (axios) => axios.post('/points/redeem', payload)
);
export const refundRedemption = (payload) => initAxios().then(
  (axios) => axios.post('/points/refund', payload)
);
export const queryPointsLogs = (sourceType, query = '') => initAxios().then(
  (axios) => axios.get(`/pointslog?source=${sourceType}&${query}`)
);

// CART
export const searchCarts = (query = '') => initAxios().then(
  (axios) => axios.get(`/carts?${query}`)
);
export const putCart = (payload) => initAxios().then(
  (axios) => axios.put('/carts', payload)
);
export const assignCart = (cartUUID, payload) => initAxios().then(
  (axios) => axios.put(`/carts/${cartUUID}/assign`, payload)
);
export const deleteCart = (cartUUID) => initAxios().then(
  (axios) => axios.delete(`/carts/${cartUUID}`)
);
export const addCartItems = (cartUUID, payload) => initAxios().then(
  (axios) => axios.post(`/carts/${cartUUID}/items`, payload)
);
export const removeCartItem = (cartUUID, itemUUID) => initAxios().then(
  (axios) => axios.delete(`/carts/${cartUUID}/items/${itemUUID}`)
);
export const updateItemQuantity = (cartUUID, itemUUID, quantity) => initAxios().then(
  (axios) => axios.post(`/carts/${cartUUID}/items/${itemUUID}/quantity/${quantity}`)
);
export const getCart = (cartUUID) => initAxios().then(
  (axios) => axios.get(`/carts/${cartUUID}`)
);
export const clearCart = (cartUUID) => initAxios().then(
  (axios) => axios.delete(`/carts/${cartUUID}/items`)
);
export const getOrderItemTotals = (data) => initAxios().then(
  (axios) => axios.post('/carts/itemtotals', data)
);
export const addCartPromoCode = (cartUUID, payload) => initAxios().then(
  (axios) => axios.post(`/carts/${cartUUID}/promocode`, payload)
);
export const removeCartPromoCode = (cartUUID) => initAxios().then(
  (axios) => axios.delete(`/carts/${cartUUID}/promocode`)
);

// CALENDARS
export const getAllCalendars = (query = '') => initAxios().then(
  (axios) => axios.get(`cal?${query}`)
);
export const getCalendarsByLocation = (locationId) => initAxios().then(
  (axios) => axios.get(`cal/locations/${locationId}`)
);
export const putCalendar = (payload) => initAxios().then(
  (axios) => axios.put('cal', payload)
);
export const getCalendar = (calendarId) => initAxios().then(
  (axios) => axios.get(`cal/${calendarId}`)
);
export const deleteCalendar = (calendarId) => initAxios().then(
  (axios) => axios.delete(`cal/${calendarId}`)
);
export const mergeCalendars = (primaryCalendarId, duplicateCalendarId) => initAxios().then(
  (axios) => axios.put(`/cal/${primaryCalendarId}/merge`, [duplicateCalendarId])
);

// EVENTS
export const getCalendarEvents = (calendarId, startDT, endDT) => initAxios().then(
  (axios) => axios.get(`cal/${calendarId}/events/all/occurrences?startDT=${startDT}&endDT=${endDT}`)
);
export const createEvent = (calendarId, payload) => initAxios().then(
  (axios) => axios.post(`cal/${calendarId}/events`, payload)
);
export const getEvent = (calendarId, eventId) => initAxios().then(
  (axios) => axios.get(`cal/${calendarId}/events/${eventId}`)
);
export const updateEvent = ({ calendarId, action, bookingAction, datetime, payload }) => initAxios().then(
  // action = from | all
  // bookingAction = keep | cancel
  (axios) => axios.put(`cal/${calendarId}/events?action=${action}&bookingAction=${bookingAction}${action === 'from' ? `&datetime=${datetime}` : ''}`, payload)
);
export const deleteEvent = ({ calendarId, eventId, action, bookingAction, datetime }) => initAxios().then(
  // action = from | all
  // bookingAction = keep | cancel
  (axios) => axios.delete(`cal/${calendarId}/events/${eventId}?action=${action}&bookingAction=${bookingAction}${action === 'from' ? `&datetime=${datetime}` : ''}`)
);
export const getCustomersForEvent = (calendarId, eventId) => initAxios().then(
  (axios) => axios.get(`cal/${calendarId}/events/${eventId}/customers`)
);
export const getEventRelations = (calendarId, eventId, relation) => initAxios().then(
  (axios) => axios.get(`cal/${calendarId}/events/${eventId}/${relation}`)
);
export const updateEventRelations = (calendarId, eventId, relation, payload) => initAxios().then(
  (axios) => axios.put(`cal/${calendarId}/events/${eventId}/${relation}`, payload)
);
export const getRelatedCalEvents = (calendarId, eventId, startDT, endDT) => initAxios().then(
  (axios) => axios.get(`cal/${calendarId}/events/${eventId}/occurrences?startDT=${startDT}&endDT=${endDT}`)
);
export const getCalEvent = (calendarId, eventId, dateTime) => initAxios().then(
  (axios) => axios.get(`cal/${calendarId}/events/${eventId}/occurrences/${dateTime}`)
);
export const updateCalEvent = (calendarId, eventId, dateTime, payload) => initAxios().then(
  (axios) => axios.put(`cal/${calendarId}/events/${eventId}/occurrences/${dateTime}`, payload)
);
export const deleteCalEvent = (calendarId, eventId, dateTime) => initAxios().then(
  (axios) => axios.delete(`cal/${calendarId}/events/${eventId}/occurrences/${dateTime}`)
);
export const getValidEventsForTicketType = (ticketTypeId, query = '') => initAxios().then(
  (axios) => axios.get(`/tickettypes/${ticketTypeId}/events?${query}`)
);
export const getEventCustomers = (eventId) => initAxios().then(
  (axios) => axios.get(`events/${eventId}/customers`)
);
export const getEventTickets = (eventId) => initAxios().then(
  (axios) => axios.get(`events/${eventId}/tickets`)
);
export const getEventAttendeeList = (eventId) => initAxios().then(
  (axios) => axios.get(`events/${eventId}/attendeelist`)
);
export const getEventAttendeeListCSV = (eventId) => initAxios().then(
  (axios) => axios.get(`events/${eventId}/attendeelist/csv`)
);
export const getEventTicketHolders = (eventId, queryParams = '') => initAxios().then(
  (axios) => axios.get(`events/${eventId}/ticketholders?${queryParams}`)
);
export const getEventAttendeesAndFormCSV = (eventId) => initAxios().then(
  (axios) => axios.get(`events/${eventId}/export`)
);

// BOOKINGS
export const putBooking = (payload) => initAxios().then(
  (axios) => axios.put('bookings', payload)
);
export const getBooking = (bookingId) => initAxios().then(
  (axios) => axios.get(`bookings/${bookingId}`)
);
export const getBookingCustomers = (bookingId) => initAxios().then(
  (axios) => axios.get(`bookings/${bookingId}/customers`)
);
export const getBookingAttendees = (bookingId, query = '') => initAxios().then(
  (axios) => axios.get(`bookings/${bookingId}/attendees?${query}`)
);
export const addCustomerToBooking = (bookingId, customerId) => initAxios().then(
  (axios) => axios.post(`bookings/${bookingId}/customers/${customerId}`, {})
);
export const deleteCustomerFromBooking = (bookingId, customerId) => initAxios().then(
  (axios) => axios.delete(`bookings/${bookingId}/customers/${customerId}`)
);
export const addStaffToBooking = (bookingId, staffId) => initAxios().then(
  (axios) => axios.post(`bookings/${bookingId}/staff/${staffId}`, {})
);
export const deleteStaffFromBooking = (bookingId, staffId) => initAxios().then(
  (axios) => axios.delete(`bookings/${bookingId}/staff/${staffId}`)
);
export const invoiceBooking = (bookingId, payload) => initAxios().then(
  (axios) => axios.post(`bookings/${bookingId}/orders`, payload)
);
export const getBookingOrders = (bookingId) => initAxios().then(
  (axios) => axios.get(`bookings/${bookingId}/orders`)
);
export const deleteBooking = (bookingId) => initAxios().then(
  (axios) => axios.delete(`bookings/${bookingId}`)
);
export const createBookingReservation = (bookingId, payload) => initAxios().then(
  (axios) => axios.post(`bookings/${bookingId}/reservation`, payload)
);
export const cancelBookingReservation = (bookingId) => initAxios().then(
  (axios) => axios.delete(`bookings/${bookingId}/reservation`)
);
export const getBookingBalance = (bookingId) => initAxios().then(
  (axios) => axios.get(`bookings/${bookingId}/balance`)
);
export const getBookingTickets = (bookingId) => initAxios().then(
  (axios) => axios.get(`bookings/${bookingId}/tickets`)
);
export const getBookingTicketHolders = (bookingId, queryParams = '') => initAxios().then(
  (axios) => axios.get(`bookings/${bookingId}/ticketholders?${queryParams}`)
);
export const cancelReservation = (bookingId, reservationId) => initAxios().then(
  (axios) => axios.delete(`bookings/${bookingId}/reservation/${reservationId}`)
);
export const reincrementReservation = (bookingId, reservationId) => initAxios().then(
  (axios) => axios.post(`bookings/${bookingId}/reservation/${reservationId}/increment`)
);
export const decrementReservation = (bookingId, reservationId) => initAxios().then(
  (axios) => axios.post(`bookings/${bookingId}/reservation/${reservationId}/decrement`)
);
export const getBookingAttendeeList = (bookingId) => initAxios().then(
  (axios) => axios.get(`bookings/${bookingId}/attendeelist`)
);
export const getBookingAttendeeListCSV = (bookingId) => initAxios().then(
  (axios) => axios.get(`bookings/${bookingId}/attendeelist/csv`)
);
export const getBookingAttendeesAndFormCSV = (bookingId) => initAxios().then(
  (axios) => axios.get(`bookings/${bookingId}/export`)
);

// FORMS ON EVENTS
export const getEventForm = (eventId) => initAxios().then(
  (axios) => axios.get(`/form/event/${eventId}`)
);
export const upsertEventForm = (eventId, data) => initAxios().then(
  (axios) => axios.put(`/form/event/${eventId}`, data)
);
export const deleteEventForm = (eventId) => initAxios().then(
  (axios) => axios.delete(`/form/event/${eventId}`)
);

// WIW
export const getWIWCalendars = () => initAxios().then(
  (axios) => axios.get('wheniwork/calendars')
);
export const getWIWShifts = (wiwCalendarIds, startDT, endDT) => initAxios().then(
  (axios) => axios.get(`wheniwork/shifts?wiwCalendarId=${wiwCalendarIds}&startDT=${startDT}&endDT=${endDT}`)
);

// TICKETS
export const rescheduleTicket = (ticketId, payload) => initAxios().then(
  (axios) => axios.post(`tickets/${ticketId}/reschedule`, payload)
);

// NOTES
export const putBookingNote = (bookingId, payload) => putNote('booking', bookingId, payload);
export const getBookingNotesOnActivity = (bookingId) => getNotesOnActivity('booking', bookingId);
export const getBookingNotes = (bookingId, query = '') => getNotes('booking', bookingId, query);
export const getBookingNoteById = (bookingId, noteId) => getNoteById('booking', bookingId, noteId);
export const deleteBookingNote = (bookingId, noteId) => deleteNote('booking', bookingId, noteId);

// EVENTS && BOOKINGS
export const getEvents = (query = '') => initAxios().then(
  (axios) => axios.get(`/events?${query}`)
);
export const getBookings = (query = '') => initAxios().then(
  (axios) => axios.get(`/bookings?${query}`)
);

// PORTAL SETTINGS
export const getPortalSettings = () => initAxios().then(
  (axios) => axios.get('/settings/portal')
);
export const putPortalSettings = (data) => initAxios().then(
  (axios) => axios.put('/settings/portal', data)
);
export const getPortalLocationSettings = (locationId) => initAxios().then(
  (axios) => axios.get(`/locationsettings/${locationId}/portal`)
);
export const putPortalLocationSettings = (locationId, data) => initAxios().then(
  (axios) => axios.put(`/locationsettings/${locationId}`, data)
);

// CHECKINS SETTINGS
export const getCheckinSettings = () => initAxios().then(
  (axios) => axios.get('/settings/checkins')
);
export const putCheckinSettings = (data) => initAxios().then(
  (axios) => axios.put('/settings/checkins', data)
);
export const getCertificationsSettings = (locationId) => initAxios().then(
  (axios) => axios.get(`/settings/location/${locationId}/certifications`)
);
export const putCertificationsSettings = (locationId, data) => initAxios().then(
  (axios) => axios.put(`/settings/location/${locationId}/certifications`, data)
);
export const getLocationCheckinSettings = (locationId) => initAxios().then(
  (axios) => axios.get(`/locationsettings/${locationId}/checkins`)
);
export const putLocationCheckinSettings = (locationId, data) => initAxios().then(
  (axios) => axios.put(`/locationsettings/${locationId}`, data)
);

// DEFAULT SETTINGS
export const getDefaultSettings = () => initAxios().then(
  (axios) => axios.get('/settings/default')
);
export const putDefaultSettings = (data) => initAxios().then(
  (axios) => axios.put('/settings/default', data)
);

// EMAIL SETTINGS
export const getEmailSettings = () => initAxios().then(
  (axios) => axios.get('/settings/email')
);
export const putEmailSettings = (data) => initAxios().then(
  (axios) => axios.put('/settings/email', data)
);
export const postResendVerificationEmailSettings = (senderId) => initAxios().then(
  (axios) => axios.post(`/settings/postmark/resend/${senderId}`)
);
export const getMessagesSettings = () => initAxios().then(
  (axios) => axios.get('/settings/messages')
);
export const putMessagesSettings = (data) => initAxios().then(
  (axios) => axios.put('/settings/messages', data)
);

// GENERAL SETTINGS
export const getGeneralSettings = () => initAxios().then(
  (axios) => axios.get('/settings/general')
);
export const putGeneralSettings = (data) => initAxios().then(
  (axios) => axios.put('/settings/general', data)
);

// MARKETING SETTINGS
export const getMarketingSettings = () => initAxios().then(
  (axios) => axios.get('/settings/marketing')
);
export const putMarketingSettings = (data) => initAxios().then(
  (axios) => axios.put('/settings/marketing', data)
);

// POINTS SETTINGS
export const getPointsSettings = (locationId) => initAxios().then(
  (axios) => axios.get(`/locationsettings/${locationId}/points`)
);
export const putPointsSettings = (locationId, data) => initAxios().then(
  (axios) => axios.put(`/locationsettings/${locationId}`, data)
);

// SOUNDS SETTINGS
export const getSoundSettings = () => initAxios().then(
  (axios) => axios.get('/settings/sounds')
);
export const putSoundSettings = (data) => initAxios().then(
  (axios) => axios.put('/settings/sounds', data)
);
export const getLocationSoundSettings = (query) => initAxios().then(
  (axios) => axios.get(`/locationsettings?section=sounds${query ? `&${query}` : ''}`)
);
export const putLocationSoundSettings = (locationId, data) => initAxios().then(
  (axios) => axios.put(`/locationsettings/${locationId}`, data)
);
