const buildLocalizeFn = (args) => (dirtyIndex, options) => {
  const context = (
    options !== null && options !== undefined && options.context
      ? String(options.context)
      : 'standalone'
  );
  let valuesArray;
  if (context === 'formatting' && args.formattingValues) {
    const defaultWidth = args.defaultFormattingWidth || args.defaultWidth;
    const width = (
      options !== null && options !== undefined && options.width
        ? String(options.width)
        : defaultWidth
    );
    valuesArray = args.formattingValues[width] || args.formattingValues[defaultWidth];
  } else {
    const _defaultWidth = args.defaultWidth;
    const _width = (
      options !== null && options !== undefined && options.width
        ? String(options.width)
        : args.defaultWidth
    );
    valuesArray = args.values[_width] || args.values[_defaultWidth];
  }
  const index = (
    args.argumentCallback
      ? args.argumentCallback(dirtyIndex)
      : dirtyIndex
  );

  return valuesArray[index];
};


export default buildLocalizeFn;
