import { ITEM_TYPES } from 'constants/itemTypes';


export const ITEM_TYPES_REQUIRING_CUSTOMER = [
  ITEM_TYPES.PASS,
  ITEM_TYPES.MEMBERSHIP,
  ITEM_TYPES.EVENT_BOOKING_TICKET,
  ITEM_TYPES.EVENT_SERIES_TICKET,
  ITEM_TYPES.EVENT_BOOKING_INVOICE,
];

export const CANCELLABLE_ITEM_TYPES = [
  ITEM_TYPES.PASS,
  ITEM_TYPES.MEMBERSHIP,
  ITEM_TYPES.EVENT_BOOKING_TICKET,
  ITEM_TYPES.EVENT_SERIES_TICKET,
  ITEM_TYPES.GIFT_CARD,
  ITEM_TYPES.PRODUCT,
];

export const ASSIGNABLE_ITEM_TYPES = [
  ITEM_TYPES.PASS,
  ITEM_TYPES.MEMBERSHIP,
  ITEM_TYPES.PASS_OR_MEMBERSHIP,
  ITEM_TYPES.EVENT_BOOKING_TICKET,
  ITEM_TYPES.EVENT_SERIES_TICKET,
];

export const CHECKIN_AFTER_PURCHASE_ITEM_TYPES = [
  ITEM_TYPES.PASS,
  ITEM_TYPES.MEMBERSHIP,
];

export const PROMO_CODE_ITEM_TYPES = [
  ITEM_TYPES.PASS,
  ITEM_TYPES.MEMBERSHIP,
  ITEM_TYPES.PRODUCT,
  ITEM_TYPES.EVENT_BOOKING_TICKET,
  ITEM_TYPES.PRICING_TIER,
];
